import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import HttpsRedirect from 'react-https-redirect';

import {BrowserRouter} from 'react-router-dom';

ReactDOM.render(
  // <HttpsRedirect>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </HttpsRedirect>
  ,
  document.getElementById('root')
);

import React from 'react';

import {withRouter} from 'react-router';

import './aboutpage.styles.scss';

import Navbar from '../../components/navbar/narbar.component';
import SplitText from '../../components/bounceText/bounceText.component';

// import StarWarsCrawl from '../../components/starWarsCrawl/starWarsCrawl.component';
import AboutParallax from '../../components/aboutParallax/aboutParallax.component';

class AboutPage extends React.Component{
    render(){
        const {location} = this.props;
        return(
            <div>
                <Navbar location= {location.pathname}/>
                <div className = 'aboutPage'>
                    <div className = 'aboutLeft'>
                        <h1 className = 'hi about'>
                            <SplitText copy = 'About Me' role = 'heading'/>
                        </h1>
                        <p style={{'fontSize':'20px'}}>
                        Hi, I am a graduate software engineering student at the University of Calgary. I enjoy creating things with my mind and my hands.
                        I am driven and focused on the singular purpose of developing technology to solve problems in the world. 
                        I am passionate about working on ambitious projects with talented individuals.
                        </p>
                    
                    </div>
                    <div className = 'aboutRight'>
                        <AboutParallax/>
                    </div>
                </div>
                
            </div>
            

        )
    }
}

export default withRouter(AboutPage);
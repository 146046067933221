import React from 'react';

import {withRouter} from 'react-router';

import './workpage.styles.scss';
import Navbar from '../../components/navbar/narbar.component';
import CardsContainer from '../../components/springCards/springCards.container';

// import ParticlesContainer from '../../components/particles/particles.component';

class WorkPage extends React.Component{
    render(){
        const {location} = this.props;
        return(
            <div>
                <Navbar location= {location.pathname}/>
                {/* <ParticlesContainer> */}
                    <div className = 'workPage'>
                        <h1 className = 'hi mywork'>
                            Recent Experiences
                        </h1>
                        <CardsContainer/>
                    </div>  
                {/* </ParticlesContainer> */}
            </div>
        )
    }
}

export default withRouter(WorkPage);
import React from 'react';
import Typical from 'react-typical';
import {withRouter} from 'react-router';

import './homepage.styles.scss';
import '../aboutpage/aboutpage.styles.scss';

import SplitText from '../../components/bounceText/bounceText.component';
import Delayed from '../../components/delay/delay.component';
import Navbar from '../../components/navbar/narbar.component';

import me from '../../assets/pictures/me.jpg';

import AboutParallax from '../../components/aboutParallax/aboutParallax.component';
// import ParticlesContainer from '../../components/particles/particles.component';

class HomePage extends React.Component{
    
    render(){
        const {location} = this.props;

    return(
    <div>
        <Navbar location= {location.pathname}/>
        {/* <ParticlesContainer className = 'particles'> */}
        <div className = 'homePage'>
                
                <h1 className = 'hi'>
                    <SplitText copy = 'Hi,' role = 'heading'/>
                </h1>
                <h1 className = 'Iam hi'>
                    <Delayed waitBeforeShow = {1000}>
                    <SplitText copy = 'I am Leo,' role = 'heading'/>
                    </Delayed>
                    
                </h1>
                    <Typical
                        className = 'intro'
                        loop ={Infinity}
                        wrapper = "b"
                        steps = {[
                            2600,
                            'I am a software developer.',
                            2000,
                            'I am an AI researcher.',
                            2000,
                            'I am an engineering student.',
                        ]}
                    />
 
            <img className = 'cartoonMe' ref='image' src={me} alt = ''/>
            
        </div>
        {/* <div className = 'aboutPage'>
            <div className = 'aboutLeft'>
                <h1 className = 'hi about'>
                    <SplitText copy = 'About Me' role = 'heading'/>
                </h1>
                <p>
                A wise man once said, "You can't connect the dots looking forward; you can only connect them looking backward." 
                As a child, my favorite books were science encyclopedias. To me, engineers seemed like heroes and pioneers driving civilzation forward with technology,
                and science became something that I would hang on to as long as I could recall. 
                The art of artifically intelligent systems has intrigued me and led me to pursue a path in technology. 

                </p>
                <p style={{'fontSize':'20px'}}>
                Hi, I am a graduate software engineering student at the University of Calgary. I enjoy creating things with my mind and my hands.
                I have experience in software development and AI. 
                I am driven and focused on the singular purpose of developing technology to solve problems in the world. 
                I am passionate about working on ambitious projects with talented individuals.
                </p>
            
            </div>
            <div className = 'aboutRight'>
                <AboutParallax/>
            </div>
        </div> */}
        
        {/* </ParticlesContainer> */}
    </div>
    )}
};

export default withRouter(HomePage);
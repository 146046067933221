import React from 'react';

import {withRouter} from 'react-router';

import './projectpage.styles.scss';

import Navbar from '../../components/navbar/narbar.component';
import ProjectCardCarousel from '../../components/projectCardCarousel/projectCardCarousel.component';
import {ProjectData} from '../../components/projectCardCarousel/projectCardCarousel.data';

class ProjectPage extends React.Component{
    render(){
        const {location} = this.props;
        return(
            <div>
                <Navbar location= {location.pathname}/>
                <div className = 'projectPage'>
                    <h1 className = 'hi mywork' style = {{marginTop: '8vh'}}>Some of my past projects</h1>
                    <ProjectCardCarousel ProjectData = {ProjectData}/>
                </div>
                
            </div>
            

        )
    }
}



export default withRouter(ProjectPage);
import React from 'react';

import {Switch, Route} from 'react-router-dom';

import HomePage from './pages/homepage/homepage.page';
import AboutPage from './pages/aboutpage/aboutpage.page';
import WorkPage from './pages/workpage/workpage.page';
import ProjectPage from './pages/projectpage/projectpage.page';
import ContactPage from './pages/contactpage/contactpage.page';

import BB8 from './components/BB8/BB8.component';


import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path= '/' component = {HomePage}/>
        <Route exact path= '/about' component = {AboutPage}/>
        <Route exact path= '/work' component = {WorkPage}/>
        <Route exact path= '/project' component = {ProjectPage}/>
        <Route exact path= '/contact' component = {ContactPage}/>
      </Switch>
      <BB8/>
    </div>
  );
}

export default App;
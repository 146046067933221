import React from 'react';
import './projectCardCarousel.styles.scss';

import Carousel from 'react-grid-carousel';
import FlippingCard from '../flippingCard/flippingCard.component';

const ProjectCardCarousel = ({ProjectData}) => {
    const responsiveLayout = [
        {
            breakpoint: 1400,
            cols: 2,
        }
        //,{
        //     breakpoint: 900,
        //     cols: 1,
        // }
    ]

    return(
        <div className = 'projectCardCarouselContainer'>
            <Carousel cols = {3} loop gap = {2} responsiveLayout = {responsiveLayout} mobileBreakpoint={670}>
                {ProjectData.map(({id, ...otherProjectProps}) => (
                    <Carousel.Item key = {id}>
                        <FlippingCard key = {id} {...otherProjectProps}/>
                    </Carousel.Item>
                ))
                }
            </Carousel>
        </div>
    )

}

export default ProjectCardCarousel;
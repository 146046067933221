import React from 'react';

import './flippingCard.styles.scss';


class FlippingCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
          flipped: false,
          cardTitle: props.cardTitle,
          imageSrc: props.imageSrc,
          content: props.content,
          firstParagraph: props.firstParagraph,
          secondParagraph: props.secondParagraph

        };
      this.flip = this.flip.bind(this);
    }

    flip = () => {
      this.setState({ flipped: !this.state.flipped });
    }
    render() {
      return (
        <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
  
          <Front cardTitle = {this.state.cardTitle} imageSrc = {this.state.imageSrc} content= {this.state.content} />
          <Back firstParagraph = {this.state.firstParagraph} secondParagraph = {this.state.secondParagraph}/>
        </div>
      )
    }
  }

const Front = ({cardTitle, imageSrc, content})=>(
    <div className="front">
        <ImageArea cardTitle = {cardTitle} imageSrc= {imageSrc}/>
        <MainArea content = {content} />
    </div>
)

const Back = ({firstParagraph, secondParagraph}) => (
    <div className="back">
        <p>{firstParagraph}</p>
        <p>{secondParagraph}</p>
    </div>
)

const ImageArea = ({cardTitle, imageSrc}) => (
    <div className="image-container">
        <img className="card-image" src={imageSrc} alt='' style = {{marginLeft: '14px'}}></img>
        <h1 className="title">{cardTitle}</h1>
    </div>
)

const MainArea = ({content})=>(
    <div className="main-area">
        <div className="blog-post">
        <p className="blog-content">
            {content}
        </p>
        <p className="read-more">Hover to read more...</p>
        </div>
    </div>
)

export default FlippingCard;
import React from 'react';

import {Link} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Home} from '../../assets/home.svg';
import {ReactComponent as About} from '../../assets/aboutMe.svg';
import {ReactComponent as Work} from '../../assets/work.svg';
import {ReactComponent as Project} from '../../assets/project.svg';
import {ReactComponent as Contact} from '../../assets/contact.svg';

import './narbar.styles.scss';

class Navbar extends React.Component{
    constructor(){
        super();
        this.state = { 
            hoverHome: false,
            hoverMe: false,
            hoverWork: false,
            hoverProject: false,
            hoverContact: false
        };
        this.toggleHoverHome = this.toggleHoverHome.bind(this);
        this.toggleHoverMe = this.toggleHoverMe.bind(this);
        this.toggleHoverWork = this.toggleHoverWork.bind(this);
        this.toggleHoverProject = this.toggleHoverProject.bind(this);
        this.toggleHoverContact = this.toggleHoverContact.bind(this);
        this.toggleHoverHomeFalse = this.toggleHoverHomeFalse.bind(this);
        this.toggleHoverMeFalse = this.toggleHoverMeFalse.bind(this);
        this.toggleHoverWorkFalse = this.toggleHoverWorkFalse.bind(this);
        this.toggleHoverProjectFalse = this.toggleHoverProjectFalse.bind(this);
        this.toggleHoverContactFalse = this.toggleHoverContactFalse.bind(this);
    };

    toggleHoverHome(){
        this.setState(() => ({hoverHome: true}))
    };
    toggleHoverMe(){
        this.setState(() => ({hoverMe: true}))
    };
    toggleHoverWork(){
        this.setState(() => ({hoverWork: true}))
    };
    toggleHoverProject(){
        this.setState(() => ({hoverProject: true}))
    };
    toggleHoverContact(){
        this.setState(() => ({hoverContact: true}))
    };

    toggleHoverHomeFalse(){
        this.setState(() => ({hoverHome: false}))
    };
    toggleHoverMeFalse(){
        this.setState(() => ({hoverMe: false}))
    };
    toggleHoverWorkFalse(){
        this.setState(() => ({hoverWork: false}))
    };
    toggleHoverProjectFalse(){
        this.setState(() => ({hoverProject: false}))
    };
    toggleHoverContactFalse(){
        this.setState(() => ({hoverContact: false}))
    };

    render(){
        const colorTheme = '#4EF0F4';
        const currentTheme = '#FFFFFF';
        const {location} = this.props;

        return(
            <div className = 'navbar'>
                {location === '/'?
                    <div className ='logo-container'>
                        <Logo fill={colorTheme} stroke = '#FFFFFF' className = 'logo'/>
                    </div>:
                    <Link className = 'logo-container' to = '/'>
                        <Logo fill={colorTheme} stroke = '#FFFFFF' className = 'logo'/>   
                    </Link> 
                }

                <div className = 'options'>
                    {location === '/'?
                        <div onMouseEnter = {this.toggleHoverHome} onMouseLeave = {this.toggleHoverHomeFalse}>
                            {this.state.hoverHome ?
                                <span className = 'option navbarText'>Home</span>:
                                <Home fill={currentTheme} className = 'option'/>
                            }
                        </div>:
                        <Link to = '/' onMouseEnter = {this.toggleHoverHome} onMouseLeave = {this.toggleHoverHomeFalse}>
                            {this.state.hoverHome ?
                                <span className = 'option navbarText'>Home</span>:
                                <Home fill={colorTheme} className = 'option'/>
                            }
                        </Link>
                    }
                    
                    {location === '/about'?
                        <div onMouseEnter = {this.toggleHoverMe} onMouseLeave = {this.toggleHoverMeFalse}>
                            {this.state.hoverMe ?
                                <span className = 'option navbarText'>About <br/>Me</span>:
                                <About fill={currentTheme} stroke = '#FFFFFF' className = 'option'/>
                            }
                        </div>:
                        <Link to = '/about' onMouseEnter = {this.toggleHoverMe} onMouseLeave = {this.toggleHoverMeFalse}>
                            {this.state.hoverMe ?
                                <span className = 'option navbarText'>About <br/>Me</span>:
                                <About fill={colorTheme} stroke = '#FFFFFF' className = 'option'/>
                            }
                        </Link>
                    }
                    
                    {location === '/work'?
                        <div onMouseEnter = {this.toggleHoverWork} onMouseLeave = {this.toggleHoverWorkFalse}>
                            {this.state.hoverWork ?
                                <span className = 'option navbarText'>My <br/>Work</span>:
                                <Work fill={currentTheme} className = 'option'/>
                            }
                        </div>:
                        <Link to = '/work' onMouseEnter = {this.toggleHoverWork} onMouseLeave = {this.toggleHoverWorkFalse}>
                            {this.state.hoverWork ?
                                <span className = 'option navbarText'>My <br/>Work</span>:
                                <Work fill={colorTheme} className = 'option'/>
                            }
                        </Link>
                    }

                    {location === '/project'?
                        <div onMouseEnter = {this.toggleHoverProject} onMouseLeave = {this.toggleHoverProjectFalse}>
                            {this.state.hoverProject ?
                                <span className = 'option navbarText smallerText'>My <br/>Projects</span>:
                                <Project fill={currentTheme} className = 'option'/>
                            }
                        </div>:
                        <Link to = '/project' onMouseEnter = {this.toggleHoverProject} onMouseLeave = {this.toggleHoverProjectFalse}>
                            {this.state.hoverProject ?
                                <span className = 'option navbarText smallerText'>My <br/>Projects</span>:
                                <Project fill={colorTheme} className = 'option'/>
                            }
                        </Link>
                    }

                    {location === '/contact'?
                        <div onMouseEnter = {this.toggleHoverContact} onMouseLeave = {this.toggleHoverContactFalse}>
                            {this.state.hoverContact ?
                                <span className = 'option navbarText smallerText'>Contact</span>:
                                <Contact fill={currentTheme} className = 'option'/>
                            }
                        </div>:
                        <Link to = '/contact' onMouseEnter = {this.toggleHoverContact} onMouseLeave = {this.toggleHoverContactFalse}>
                            {this.state.hoverContact ?
                                <span className = 'option navbarText smallerText'>Contact</span>:
                                <Contact fill={colorTheme} className = 'option'/>
                            }
                        </Link>
                    }    
                </div>
            </div>
        )
    }
};

export default Navbar;            
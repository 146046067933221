import React from 'react';

import {withRouter} from 'react-router';

import './contactpage.styles.scss';

import Navbar from '../../components/navbar/narbar.component';
import SplitText from '../../components/bounceText/bounceText.component';

import {ReactComponent as Github} from '../../assets/linkIcons/GithubIconCopy.svg';
import {ReactComponent as LinkedIn} from '../../assets/linkIcons/LinkedInIcon.svg';
import {ReactComponent as Yahoo} from '../../assets/linkIcons/YahooIcon.svg';
import {ReactComponent as Twitter} from '../../assets/linkIcons/TwitterIcon.svg';

import MyMap from '../../components/map/map.component';

class ContactPage extends React.Component{
    render(){
        const {location} = this.props;

        const colorTheme = '#4EF0F4';
        return(
            <div>
                <Navbar location= {location.pathname}/>
                <div className = 'contactPage'>

                    <div className = 'contactLeft'>
                        <h1 className = 'hi contactMe'>
                            <SplitText copy = 'Contact Me' role = 'heading'/>
                        </h1>
                        <div style={{'fontSize': '20px'}}>
                            I am interested in any ambitious projects and opportunities. Would love to connect with like-minded individuals.
                            You can catch me hacking away at hackathons or working in my garage on the next big thing.
                        </div>
                        <a href = 'https://github.com/leowei31' target = '_blank' rel="noopener noreferrer"><Github className = 'linkIcon' fill ={colorTheme}/> </a>
                        <a href = 'https://www.linkedin.com/in/leo-wei/' target = '_blank' rel="noopener noreferrer"><LinkedIn className = 'linkIcon' fill ={colorTheme}/> </a>
                        <a href = 'mailto:leowei727@yahoo.ca' target = '_blank' rel="noopener noreferrer"><Yahoo className = 'linkIcon' fill ={colorTheme}/> </a>
                        <a href = 'https://twitter.com/leowei31' target = '_blank' rel="noopener noreferrer"><Twitter className = 'linkIcon' fill ={colorTheme}/> </a>
                    </div>

                    <div className = 'contactRight'>
                        <MyMap/>
                    </div>
                    
                </div>
                
            </div>
            

        )
    }
}

export default withRouter(ContactPage);
import React, {useState} from 'react';

import { Map, Marker } from 'pigeon-maps'

function MyMap() {
  const [center, setCenter] = useState([51.051754, -114.075635])
  const [zoom, setZoom] = useState(4)
  return (
    <Map 
      width={550}
      height={450}
      center={center} 
      zoom={zoom} 
      onBoundsChanged={({ center, zoom }) => { setCenter(center); setZoom(zoom) }}
      animate
      >
      <Marker 
        anchor={[51.080505430912865, -114.13226037539556]}
        color='#4ef0f4'
        payload={1} 
        onClick={({ event, anchor, payload }) => {
          console.log('Clicked marker nr: ', payload)
        }}
      />
    </Map>
  )
}

export default MyMap;
import React, { useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import './springCards.styles.scss';

const CardsContainer = () =>{
    return (
        <div className="card-main">
            <Hero>
              <div className="spring-card-container">
                <div className="card-row">
                  {cards.map((card, i) => (
                    <div className="card-column">
                      <Card key= {i}>
                        <div className="card-title">{card.title}</div>
                        <div className="card-job">{card.job}</div>
                        <div className="card-date">{card.date}</div>
                        <div className="card-body">
                          <ul className="description-list">
                            {card.description.map((des, i) => (
                              <li>{des}</li>
                            ))}
                          </ul>
                          </div>
                        {/* <Image ratio={card.imageRatio} src={card.image} /> */}
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </Hero>
        </div>
    );
}

function Card({ children }) {
    // We add this ref to card element and use in onMouseMove event ...
    // ... to get element's offset and dimensions.
    const ref = useRef();
  
    // Keep track of whether card is hovered so we can increment ...
    // ... zIndex to ensure it shows up above other cards when animation causes overlap.
    const [isHovered, setHovered] = useState(false);
  
    const [animatedProps, setAnimatedProps] = useSpring(() => {
      return {
        // Array containing [rotateX, rotateY, and scale] values.
        // We store under a single key (xys) instead of separate keys ...
        // ... so that we can use animatedProps.xys.interpolate() to ...
        // ... easily generate the css transform value below.
        xys: [0, 0, 1],
        // Setup physics
        config: { mass: 20, tension: 300, friction: 50, precision: 0.00001 }
      };
    });
  
    return (
      <animated.div
        ref={ref}
        className="springCard"
        onMouseEnter={() => setHovered(true)}
        onMouseMove={({ clientX, clientY }) => {
          // Get mouse x position within card
          const x =
            clientX -
            (ref.current.offsetLeft -
              (window.scrollX || window.pageXOffset || document.body.scrollLeft));
  
          // Get mouse y position within card
          const y =
            clientY -
            (ref.current.offsetTop -
              (window.scrollY || window.pageYOffset || document.body.scrollTop));
  
          // Set animated values based on mouse position and card dimensions
          const dampen = 100; // higher the number the less rotation
          const xys = [
            -(y - ref.current.clientHeight / 2) / dampen, // rotateX
            (x - ref.current.clientWidth / 2) / dampen, // rotateY
            1.07 // Scale
          ];
  
          // Update values to animate to
          setAnimatedProps({ xys: xys });
        }}
        onMouseLeave={() => {
          setHovered(false);
          // Set xys back to original
          setAnimatedProps({ xys: [0, 0, 1] });
        }}
        style={{
          // If hovered we want it to overlap other cards when it scales up
          zIndex: isHovered ? 2 : 1,
          // Interpolate function to handle css changes
          transform: animatedProps.xys.interpolate(
            (x, y, s) =>
              `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
          )
        }}
      >
        {children}
      </animated.div>
    );
  }
  
  function Hero({ children }) {
    return (
      <div className="hero">
        <div className="hero-body">{children}</div>
      </div>
    );
  }
  
  function Image({ ratio, src }) {
    return (
      <div className="image-container">
        <div className="image-inner-container">
          <div
            className="ratio"
            style={{
              paddingTop: ratio * 100 + "%"
            }}
          >
            <div className="ratio-inner">
              <img src={src} alt = "cardPhoto"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  const cards = [
    {
      title: "CyberX, Crypto Market Maker",
      job: "Machine Learning Engineer",
      date: "May 2023 - Aug 2023",
      description: ["Spearheaded application of unsupervised learning techniques for predicting and ranking individuals’ performances to maximize profit as a liquidity provider for Uniswap V3"],
      image: "https://cdn2.iconfinder.com/data/icons/artificial-intelligence-6/64/ArtificialIntelligence28-512.png",
      imageRatio: 512 / 512
    },
    {
      title: "KPMG",
      job: "Software Engineer Intern",
      date: "Sept 2022 - Dec 2022",
      description:
        ["Introduced and integrated isolated component testing using Storybook for React components.",
        "Redesigned backend architecture to leverage Object Oriented Programming and SOLID principles, resulting in improved scalability and code organization",
        "Introduced code profiling and improved data analysis tool runtime by 20%."],
      image: "https://cdn2.iconfinder.com/data/icons/artificial-intelligence-6/64/ArtificialIntelligence28-512.png",
      imageRatio: 512 / 512      
    },
    {
      title: "UofC ESE",
      job:"Machine Learning Researcher",
      date: "May 2022 - Present",
      description:
        ["Research in federated learning with focus on reducing variance when statistical heterogeneity is present.", 
        "Used FlowerFL, and PyTorch to develop a federated learning system with LSTM for time-series data.",
        "TA: ENSF381 (full stack development, React, AWS, Terraform), ENSF694 (algorithms and data structures), ENEL500(software engineering capstone)"],
      image: "https://cdn2.iconfinder.com/data/icons/artificial-intelligence-6/64/ArtificialIntelligence28-512.png",
      imageRatio: 512 / 512
    },
    // {
    //   title: "Healthcare Systems Research & Analysis",
    //   job:"Software Developer",
    //   date: "Jan 2021 - Apr 2021",
    //   description:
    //     ["Worked closely with doctors and other computer science researchers, design and developed mathematical models in Python to find correlation in muscle activity data with pain response data, implemented IIR and Kalman filter with Numpy. ", "Worked with clinical doctors on research in methods to convert unstructured text to structured text."],
    //   image: "https://cdn2.iconfinder.com/data/icons/artificial-intelligence-6/64/ArtificialIntelligence16-512.png",
    //   imageRatio: 512/512
    // },
    {
      title: "UBC Department of Mathematics",
      job:"Research Developer",
      date: "Jul 2020 - Aug 2020",
      description:
        ["Implemented custom Gym environment simulating modern control system with cost function and state value functions.", "Used modern reinforcement learning to most effectively tune engineering controller parameters, used Pytorch to implement reinforcement learning algorithm Deep Q Network and DDPG."],
      image: "https://cdn2.iconfinder.com/data/icons/artificial-intelligence-6/64/ArtificialIntelligence12-512.png",
      imageRatio: 512/512
    }
    
  ];

export default CardsContainer;
import Volee from '../../assets/projects/Volee.JPG';
import BizHacks from '../../assets/projects/Bizhacks.JPG';
import ThinkTECH from '../../assets/projects/Deloitte ThinkTECH.JPG';
import Drone from '../../assets/projects/Drone.JPG';
import DataVis from '../../assets/projects/patient2ex5.gif';
import RL from '../../assets/projects/RL.JPG';
import DineIn from '../../assets/projects/DineIN.JPG';
import Nectarfy from '../../assets/projects/Nectarfy.JPG';
import HackTheNorth from '../../assets/projects/HackTheNorth.png';
import CANIS from '../../assets/projects/CANIS.png';
import TechStart from '../../assets/projects/TechStart.gif';

export const ProjectData = [
    {
        cardTitle: 'Hack the North 2023',
        imageSrc: `${HackTheNorth}`,
        content: 'Developed a MS Teams Bot for summarizing meeting notes and action items',
        id: 13,
        firstParagraph: 'Used Flask, React JS, CockroachDB, MS Teams API, Cohere LLM API to build a MS Teams bot which summarizes group conversations, aggregates all direct message and generates calendar with to dos.',
        secondParagraph: '',
    },
    {
        cardTitle: 'Canadian Network on Information and Security Hackathon 2023, Winner',
        imageSrc: `${CANIS}`,
        id: 12,
        content: 'Used Spacy, Python NLTK, pandas, D3 JS, and Express to build a fully functioning data analytics full stack application',
        firstParagraph:' Used Spacy, Python NLTK, pandas, D3 JS, and Express to build a fully functioning data analytics full stack application',
        secondParagraph:''
    },
    {
        cardTitle: 'RL robotic arm, TechStart',
        imageSrc: `${TechStart}`,
        content: 'Led a team of 7 students to build a robotic arm capable of picking up objects using reinforcement learning',
        id: 11,
        firstParagraph: 'Led a group of 7 software engineering students on a reinforcement learning robotics arm project, used OpenCV, OpenAI gym, PyTorch, implemented DDPG algorithm',
        secondParagraph: '',
    },
    {
        cardTitle: 'New Venture Design, Entreprenuership@UBC',
        imageSrc: `${Nectarfy}`,
        content: 'Built a fully functioning smart beehive with a companion app in the hope of combating mites and climate issues faced by bees around the world.  ',
        id: 10,
        firstParagraph: 'In the 1950s, it would be a bad year if beekeepers lost 10% of their bees. Nowadays, it would be a good year if beekeepers lost 30%. Our team aims to solve these problems, we went through the New Venture Design Program and the E@UBC Incubator.',
        secondParagraph: 'Led the development of the mobile app, built with Flutter, MongoDB, Node, Express, and Bluetooth Low Energy, won first place at Demo day, and second place in RBC get seeded.'
    },
    {
        cardTitle: 'Data Visualization for KIMORE dataset',
        imageSrc: `${DataVis}`,
        content: 'Built an animation tool for visualizing the kinematic assessment of movement and clinical scores for remote monitoring of physical rehabilitation',
        id: 9,
        firstParagraph: 'Used sensor inputs from skeleton joint positions as data.',
        secondParagraph: 'Performed data preprocessing and normalization on raw csv data, applied filters to smoothen data. Used matplotlib and numpy to create animations. '
    },{
        cardTitle: 'Reinforcement Learning, UBC Math',
        imageSrc: `${RL}`,
        content: 'Applied reinforcement learning algorithm DDPG onto modern engineering control systems with the goal of tuning engineering controller parameters efficiently',
        id: 8,
        firstParagraph: 'Incorporated reinforcement learning algorithm such as DQN and DDPG into engineering systems, where our agent would determine the parameters and coefficients that go into the controllers.',
        secondParagraph: 'The reinforcement learning agent would try to maximize its reward, with its reward function being the difference between the desired and current state. In the end, the agent could tune the parameters much more efficiently than traditional trial and error methods.'
    },{
        cardTitle: 'NWHacks 2021 (Dine In)',
        imageSrc: `${DineIn}`,
        content: 'Developed a web application made for restaurant customers to place orders through the app and contact tracing for COVID 19',
        id: 7,
        firstParagraph: 'Made with ReactJS for frontend because of its scalability with reusable components and firestore for backend authentication for users and storing menu items.',
        secondParagraph: ''
    },{
        cardTitle: 'NWHacks 2020 (Volee)',
        imageSrc: `${Volee}`,
        content: 'Developed a web application for connecting Volunteers and Non-profit organizations',
        id: 5,
        firstParagraph: 'Built to streamline the process of volunteer applications and volunteer recruitments.',
        secondParagraph: 'Used Node JS, Express, and MySQL for backend development and Bootstrap for frontend UI with Google Maps Integration.'
    },{
        cardTitle: 'BizHacks x BestBuy 2020',
        imageSrc: `${BizHacks}`,
        content: 'Developed web-based customer loyalty application for Best Buy by performing data analysis on consumer behaviours',
        id: 4,
        firstParagraph: 'Performed data analysis and calculated a loyalty score on customer behaviours such as purchasing and return frequency. Using the loyalty score, the app would give customers personalized incentives and discounts.',
        secondParagraph: 'Built using pandas, and a Django web-based framework, and then used Node JS, Express for fetching analyzed data, and embedded JavaScript for frontend web pages with built in logic.'
    },{
        cardTitle: 'Deloitte, ThinkTECH',
        imageSrc: `${ThinkTECH}`,
        content: 'Deloitte case competition with UBC and SFU',
        id: 3,
        firstParagraph: 'In a team of five, identified problem with client (HSBC) and develop and formulate a business solution for the future of open banking.',
        secondParagraph: 'Devise and design of technical breakdown and define proposed application program interface (API) structure.'
    },{
        cardTitle: 'Drone with Autonomous Features',
        imageSrc: `${Drone}`,
        content: 'Built my own drone capable of flying at high speed and autonomous arm for picking up objects',
        id: 1,
        firstParagraph: 'Worked with flight controller, power distribution board, battery eliminating circuit to design and build a remote-controlled drone. Spec out electronics components through careful estimation for mechanical system.',
        secondParagraph: 'Added various sensors onboard such as infared, ultrasonic sensors for obstacle detection and picking up objects.'
    }
]